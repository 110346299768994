@import url("https://fonts.googleapis.com/css2?family=Chivo&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  font-family: "HKGrotesk";
}

.arrow-icon {
  vertical-align: middle;
  height: 16px;
  padding-bottom: 2px;
}

em {
  font-style: normal;
  font-weight: 700;
  margin: 0;
}

@font-face {
  font-family: "HKGrotesk";
  src: url("./fonts/HKGrotesk-Light.otf") format("opentype");
  font-style: light;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "HKGrotesk";
  src: url("./fonts/HKGrotesk-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "HKGrotesk";
  src: url("./fonts/HKGrotesk-SemiBold.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "HKGrotesk";
  src: url("./fonts/HKGrotesk-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

h1,
h2,
h3,
h4 {
  font-family: "Chivo", sans-serif;
}

.pre-body {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.body-box {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
}

.main-container {
  box-sizing: border-box;
  flex: 1;
  margin: 24px 12px;
  max-width: 540px;
  min-width: auto;
}

p {
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 24px;
  color: #0a162a;
  font-weight: 400;
}

.song-item {
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
}

.song-item:hover {
  background-color: #b4d8fa;
  border-radius: 4px;
}

@media screen and (min-width: 640px) {
  .main-container {
    margin: 32px auto;
    min-width: 520px;
  }
}

.tag {
  font-family: HkGrotesk;
  font-size: 12px;
  line-height: 0;
  white-space: nowrap;

  color: #fff;
  border-radius: 10px;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  padding: 0px 8px;
  height: 28px;
}

.tag:hover {
  opacity: ".85";
}

.search-option-box:hover {
  background-color: #bbd6fb;
}

.link {
  font-family: HKGrotesk;
  color: #155da1;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
}

.link:hover {
  text-decoration: underline;
  color: #0e4e8a;
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.pdf-viewer {
  width: 96%;
}

.is-content-search-icon {
  cursor: pointer;
  min-width: 24px;
}

.settings-inner-box {
  display: flex;
  flex: flex-wrap;
  padding: "0 4px 4px 0";
  border-bottom: "1px solid #d1d5da";
  min-height: "22px";
}

.settings-popup-box {
  transition: all 0.2s ease-in-out, border-radius 0.2s step-start,
    border 0.2s ease-in-out;
  width: 180px;
  border-radius: 6px;
  position: absolute;
  z-index: 100;

  background: #fff;
  padding: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.line {
  border-top: 1px solid #d1d5da;
  border-bottom: 0px;
}

.toggle-btn {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  border: none;
  border-radius: 6px;
  flex: 1;
  max-width: fit-content;
}

.active {
  border: 1px solid #3F7DC1;
  background-color: #3F7DC1;
}

.inactive {
  border: 1px solid #B3B3B3;
}

.toggle-left-btn {
  flex: 1;
  border-right: none;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  text-align: center;
  padding: 0 8px;
}

.toggle-right-btn {
  flex: 1;
  border-left: none;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  text-align: center;
  padding: 0 8px;
}

.arrow-right-icon {
  vertical-align: middle;
  height: 16px;
  padding-bottom: 2px;
}

.music-icon {
  vertical-align: middle;
  height: 16px;
  padding-right: 6px;
  padding-bottom: 2px;
}

.audio-player:focus {
  outline: none;
}

.detail-text {
  margin-left: 2px;
  font-size: 12px;
  color: #8b9199;
  cursor: pointer;
}

.detail-icon {
  color: #8b9199;
  padding-right: 4px;
  display: inline-block;
  height: 8px;
}